@import url(https://fonts.googleapis.com/css2?family=Fira+Code:wght@300;400;500;600;700&display=block);
@import url(some-utils/css/main.css);
@import url(some-utils/css/inputs.css);
@import url(some-utils/css/layout.css);
@import url(ModalContent.css);

/* html scope because of modal rendered outside of <root/> */
html {
  --dark: #222;

  --dark-1: #18141a;
  --dark-2: #1c1823;
  --dark-2: #221e2a;

  --medium-1: #3a3248;
  --medium-1-33: #3a324833;
  --medium-1-66: #3a324866;
  --medium-1-cc: #3a3248cc;

  --medium-2: #4f4462;
  --medium-2-33: #4f446233;
  --medium-2-66: #4f446266;
  --medium-2-cc: #4f4462cc;
  
  --medium-3: #725f93;

  /* --highlight-1: #63c2ff; */
  --highlight-1: #ff5f3e;
  --highlight-1-cc: #ff5f3ecc;
  --highlight-2: #fe4c28;

  --font-color: #c8cdbb;

  --shadow: #2222 16px 16px 16px, inset white 0px 0px 32px;
  --shadow-8: #2222 8px 8px 8px, inset white 0px 0px 8px;
  --border: solid 1px var(--medium-2);

  background-color: var(--dark-1);
  color: var(--font-color);
  font-size: 20px;
}

.desktop {
  font-size: 16px;
}

.mobile {
  font-size: 14px;
}

main {
  flex: 1;
}

header.Header {
  height: 3em;
  padding: .5em;
  align-items: center;
}

input,
button:not(.link):not(.blank) {
  border-color: var(--medium-1);
  background-color: var(--medium-2-33);
  transition: background .3s ease-out;
  border-radius: 4px;
}

input:focus {
  transition-duration: .05s;
  background-color: var(--medium-2-66);
}

@media (hover) {
  button:not(.link):not(.blank):focus,
  button:not(.link):not(.blank):hover {
    transition-duration: .05s;
    background-color: var(--medium-2-66);
  }
}

.dim {
  font-weight: 300;
  opacity: .33;
}

input {
  transition: all .5s ease-out;
}
input.invalid {
  background-color: #d14444;
  transition-duration: 0s;
}

.small {
  font-size: .8em;
}

.MuiPaper-root {
  border-radius: 4px;
  background-color: var(--medium-1);
  color: var(--font-color);
}

@keyframes spinner {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}

