.SurveyPage .VoteButtons button {
  padding: 32px;
  font-size: 3em;
}

.SurveyPage h1 .Id {
  opacity: .75;
  font-weight: 400;
  font-size: 16px;
  font-family: 'Fira Code', monospace;
  padding: 8px;
  background-color: var(--dark-2);
}

.mobile .SurveyPage .StatusButton {
  font-size: 1.6em;
}
.SurveyPage .StatusButton {
  padding: 64px;
  font-size: 2em;
  --color: var(--medium-1);
  color: var(--color);
}
.SurveyPage .StatusButton .Blink {
  background-color: var(--color);
  border-radius: 4px;
  animation: status-button-blink 1s ease-in-out 0s infinite;
}
.SurveyPage .StatusButton .Border {
  border: solid 1px var(--color);
  border-radius: 4px;
}

.SurveyPage .StatusButton.live {
  --color: var(--highlight-1);
}
.SurveyPage .StatusButton.paused {
  --color: var(--medium-2);
}
.SurveyPage .StatusButton.paused .Content {
  --color: var(--font-color);
}
.SurveyPage .StatusButton .Content .Icon {
  font-size: 1.6em;
}
.SurveyPage .StatusButton.paused .Blink {
  animation-duration: 2s;
}
.SurveyPage .StatusButton.close .Content {
  font-style: italic;
}
.SurveyPage .StatusButton.close .Blink {
  display: none;
}
.SurveyPage .StatusButton.live svg {
  animation: spinner 8s linear 0s infinite;
}

@keyframes status-button-blink {
  0% {
    opacity: .05;
  }
  40% {
    opacity: .1;
  }
  100% {
    opacity: .05;
  }
}

.SurveyPage .Progress {
  height: 78px;
  font-family: 'Fira Code', monospace;
  font-weight: 900;
  font-size: 1.2em;
}

.SurveyPage .Progress .Counter {
  padding: 0 1em;
  align-items: center;
}

.SurveyPage .ActionButtons {
  font-size: 1.2em;
} 

.SurveyPage .EditButton {
  font-size: 1rem;
  width: 2rem;
  height: 2rem;
  padding: 0;
}
