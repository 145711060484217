
.ModalContent {
  padding: 64px;
  background-color: var(--dark-2);
  color: var(--font-color);
  text-align: center;
}

.ModalContent h1 {
  color: var(--highlight-1);
}

.ModalContent button {
  padding: 16px 24px;
}
