
.fill {
  width: 100%;
  height: 100%;
}

.vh-100 {
  height: 100vh;
}

.padding-1em {
  padding: 1em;
}

.padding-2em {
  padding: 2em;
}

.padding-4 {
  padding: 4px;
}
.padding-8 {
  padding: 8px;
}
.padding-16 {
  padding: 16px;
}
.padding-32 {
  padding: 32px;
}

.text-center {
  text-align: center;
}

.pre {
  white-space: pre;
}

.overflow-hidden {
  overflow: hidden;
}

.flex {
  display: flex;
  --gutter: 0;
}

.flex.row {
  flex-direction: row;
}
/* https://developer.mozilla.org/en-US/docs/Web/CSS/Attribute_selectors */
.flex[class*="gutter"].row > * + * {
  margin-left: var(--gutter);
}

.flex.column {
  flex-direction: column;
}
.flex.column[class*="gutter"] > * + * {
  margin-top: var(--gutter);
}

.flex.center {
  align-items: center;
  justify-content: center;
}

.flex.align-center {
  align-items: center;
}

.flex.gutter-4 > * {
  --gutter: 4px;
}
.flex.gutter-8 > * {
  --gutter: 8px;
}
.flex.gutter-16 > * {
  --gutter: 16px;
}

.flex > .stretch {
  align-self: stretch;
}

.flex > .expand {
  flex: 1;
  align-self: stretch;
}

.flex.expand-content > * {
  flex: 1;
}



.flex > .space-4 {
  flex: 0 0 4px;
  align-self: stretch;
}

.flex > .space-8 {
  flex: 0 0 8px;
  align-self: stretch;
}

.flex > .space-16 {
  flex: 0 0 16px;
  align-self: stretch;
}

.flex > .space-32 {
  flex: 0 0 32px;
  align-self: stretch;
}



.flex > .space-1em {
  flex: 0 0 1em;
  align-self: stretch;
}

.flex > .space-2em {
  flex: 0 0 2em;
  align-self: stretch;
}

.flex > .space-3em {
  flex: 0 0 3em;
  align-self: stretch;
}

.flex > .space-4em {
  flex: 0 0 4em;
  align-self: stretch;
}
