/* "html body" are only here to give some strength to the selector */
/* https://developer.mozilla.org/en-US/docs/Web/CSS/Specificity */

html body .DivRoute {
  /* Force block display (because flex break layout). */
  display: block;
}

html body .DivRoute > div:not(.flex-column-center) {
  /* Force block display (because flex break layout). */
  display: block;
}

html body .DivRoute.leaving {
  /* No pointer events on a leaving route. Make sense. */
  pointer-events: none;
}

html body .DivRoute.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  /* width: 100%; */
  height: 100%;
  /* THE-SCROLL_PROBLEM */
  overflow: scroll;
  /* -webkit-overflow-scrolling: touch; */
  overscroll-behavior: contain;
}

html body .DivRoute:not(.overlay) > div {
  height: 100%;
}
html body .DivRoute.overlay > div {
  /* NOTE: No overflow scroll by default, since this triggers scrollbar on windows (even if there's no scroll) */
  /* But, be carefull, a previous case required this to be here. Keep it in mind. If possible. */
  /* overflow: scroll; */
  min-height: 100%;
}
