.RatioBar.live {
  --color: var(--highlight-1-cc);
}

.RatioBar.paused {
  --color: var(--medium-2-cc);
}

.RatioBar.close {
  --color: var(--medium-1-cc);
}

.RatioBar {
  border: solid 1px var(--color);
  border-radius: 4px;
  overflow: hidden;
}

.RatioBar .Separator,
.RatioBar .Bar {
  background-color: var(--color);
}

.RatioBar .Negative {
  opacity: .25;
}
