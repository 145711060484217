
input,
button {
  font-family: inherit;
  font-size: inherit;
  color: inherit;
  background-color: transparent;
  border: solid 1px currentColor;
  border-radius: 2px;
  padding: .3em .6em;
}

input.border-bottom,
button.border-bottom {
  border-top: unset;
  border-left: unset;
  border-right: unset;
  border-radius: 0;
}

input::placeholder {
  color: inherit;
  opacity: .5;
  font-style: italic;
}

input:focus,
button:focus {
  outline: none;
}

button {
  cursor: pointer;
  user-select: none;
}

button:not(.blank).disabled {
  font-style: italic;
  opacity: .5;
  pointer-events: none;
}

button:not(.blank).link {
  appearance: none;
  padding: 0;
  border: none;
  outline: none;
}

@media (hover) {
  button:not(.blank).link:focus,
  button:not(.blank).link:hover {
    text-decoration: underline;
  }
}

button.blank {
  border: unset;
}

