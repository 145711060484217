.SurveyList {
  padding: 32px;
}

.SurveyList .SurveyListRow {
  height: 3em;
  --border-opacity: 0;
  opacity: .8;
}

.SurveyList .SurveyListRow:hover {
  --border-opacity: 1;
  opacity: 1;
}

.SurveyList .SurveyListRow.close {
  color: var(--medium-3);
  font-style: italic;
}

.SurveyList .SurveyListRow .Inner {
  padding: 0 0.5em;
}

.SurveyList .SurveyListRow .NameSpan {
  white-space: pre;
  text-overflow: ellipsis;
}
.SurveyList .SurveyListRow svg {
  flex: 0 0 auto;
}

.SurveyList .SurveyListRow .Border {
  border-radius: 4px;
  border: solid 3px currentColor;
  opacity: var(--border-opacity);
}

.SurveyList .SurveyListRow.live .Border {
  color: var(--highlight-1);
}

.SurveyList .SurveyListRow.paused .Border {
  color: var(--medium-2);
}

.SurveyList .SurveyListRow.close .Border {
  color: var(--medium-1);
}


.SurveyList .SurveyListRow .Option {
  width: 24px;
}

.SurveyList .SurveyListRow .Option.Close svg {
  transform: scale(1.4);
}

.SurveyList .SurveyListRow .Option.Live svg {
  animation: spinner 8s linear 0s infinite;
}

.SurveyList .SurveyListRow.static input {
  pointer-events: none;
}

.SurveyList .SurveyListRow .small {
  opacity: .33;
  font-weight: 300;
}

.SurveyList .PlusButton {
  color: var(--highlight-1);
  align-self: center;
  padding: 1em;
  margin: 1em;
  width: 6em;
  height: 6em;
  transition: transform .3s ease-out;
}
.SurveyList .PlusButton:hover {
  transition-duration: 0.1s;
  transform: scale(1.1);
}
